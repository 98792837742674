import * as React from "react"

// markup
const Name = ({scroll}) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 379 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="NameContainer" className={ scroll ? "unloadName" : "loadName"} >
                <g id="FName">
                    <path id="FJ" d="M33.12 27.56H19.008V14.888H46.224V44.624C46.224 49.088 45.336 52.952 43.56 56.216C41.784 59.432 39.336 61.88 36.216 63.56C33.144 65.24 29.712 66.08 25.92 66.08C22.272 66.08 18.888 65.168 15.768 63.344C12.696 61.52 10.248 59.072 8.424 56C6.6 52.88 5.688 49.496 5.688 45.848H18.792C18.792 48.056 19.416 49.904 20.664 51.392C21.96 52.88 23.688 53.624 25.848 53.624C28.152 53.624 29.928 52.904 31.176 51.464C32.472 49.976 33.12 47.816 33.12 44.984V27.56Z" fill="black"/>
                    <path id="FA" d="M103.256 65H56.024L79.64 13.808L103.256 65ZM60.2 62.336H99.08L79.64 20.144L60.2 62.336Z" fill="black"/>
                    <path id="FC" d="M153.304 46.064C153.352 52.352 151.552 57.272 147.904 60.824C144.256 64.328 139.24 66.08 132.856 66.08C129.064 66.08 125.56 65.216 122.344 63.488C119.176 61.76 116.656 59.312 114.784 56.144C112.912 52.976 111.976 49.328 111.976 45.2V34.688C111.976 30.608 112.864 26.984 114.64 23.816C116.464 20.648 118.936 18.2 122.056 16.472C125.224 14.696 128.752 13.808 132.64 13.808C139.024 13.808 144.016 15.56 147.616 19.064C151.264 22.52 153.112 27.416 153.16 33.752H140.2C140.152 31.304 139.48 29.408 138.184 28.064C136.936 26.72 135.088 26.048 132.64 26.048C130.432 26.048 128.608 26.744 127.168 28.136C125.728 29.528 125.008 31.496 125.008 34.04V45.776C125.008 48.32 125.752 50.312 127.24 51.752C128.776 53.144 130.648 53.84 132.856 53.84C137.896 53.84 140.416 51.248 140.416 46.064H153.304Z" fill="black"/>
                    <path id="FQ" d="M185.496 13.52C189.288 13.52 192.768 14.456 195.936 16.328C199.152 18.2 201.696 20.744 203.568 23.96C205.44 27.128 206.376 30.608 206.376 34.4V45.416C206.376 48.296 205.776 51.128 204.576 53.912L211.56 60.752L202.704 69.968L196.008 63.416C192.696 65.336 189.192 66.296 185.496 66.296C181.704 66.296 178.2 65.36 174.984 63.488C171.816 61.616 169.296 59.096 167.424 55.928C165.552 52.712 164.616 49.208 164.616 45.416V34.4C164.616 30.608 165.552 27.128 167.424 23.96C169.296 20.744 171.816 18.2 174.984 16.328C178.2 14.456 181.704 13.52 185.496 13.52ZM177.72 45.416C177.72 47.528 178.464 49.352 179.952 50.888C181.488 52.424 183.312 53.192 185.424 53.192C187.536 53.192 189.336 52.424 190.824 50.888C192.36 49.352 193.128 47.528 193.128 45.416V34.4C193.128 32.288 192.36 30.488 190.824 29C189.336 27.512 187.536 26.768 185.424 26.768C183.312 26.768 181.488 27.512 179.952 29C178.464 30.488 177.72 32.288 177.72 34.4V45.416Z" fill="black"/>
                    <path id="FU" d="M238.64 66.08C234.992 66.08 231.608 65.168 228.488 63.344C225.416 61.52 222.968 59.072 221.144 56C219.32 52.88 218.408 49.496 218.408 45.848V14.888H258.872V45.848C258.872 49.496 257.96 52.88 256.136 56C254.312 59.072 251.84 61.52 248.72 63.344C245.648 65.168 242.288 66.08 238.64 66.08Z" fill="black"/>
                    <path id="FE" d="M273.64 14.888H310.144V27.56H286.744V33.896H306.184V45.92H286.744V52.328H310.144V65H273.64V14.888Z" fill="black"/>
                    <path id="FS" d="M345 66.296C338.04 66.296 332.664 64.664 328.872 61.4C325.128 58.088 323.28 53.144 323.328 46.568H335.064C335.016 49.304 335.832 51.392 337.512 52.832C339.192 54.272 341.544 54.992 344.568 54.992C347.064 54.992 349.032 54.584 350.472 53.768C351.912 52.952 352.632 51.704 352.632 50.024C352.632 48.632 351.888 47.624 350.4 47C348.96 46.376 347.52 45.944 346.08 45.704C344.64 45.416 343.752 45.248 343.416 45.2C339.72 44.48 336.528 43.616 333.84 42.608C331.152 41.552 328.896 39.968 327.072 37.856C325.248 35.744 324.336 32.96 324.336 29.504C324.336 26.048 325.248 23.12 327.072 20.72C328.896 18.272 331.32 16.448 334.344 15.248C337.416 14.048 340.752 13.448 344.352 13.448C348.72 13.448 352.488 14.264 355.656 15.896C358.872 17.528 361.392 20 363.216 23.312C364.128 24.896 364.68 26.432 364.872 27.92C365.064 29.36 365.16 30.776 365.16 32.168H353.424C353.424 29.576 352.728 27.68 351.336 26.48C349.944 25.232 347.784 24.608 344.856 24.608C342.504 24.608 340.68 25.016 339.384 25.832C338.088 26.648 337.44 27.752 337.44 29.144C337.44 30.68 338.304 31.808 340.032 32.528C341.76 33.248 343.944 33.896 346.584 34.472C350.904 35.336 354.264 36.176 356.664 36.992C359.112 37.76 361.224 39.152 363 41.168C364.776 43.184 365.664 46.016 365.664 49.664C365.664 53.12 364.704 56.096 362.784 58.592C360.864 61.088 358.32 63.008 355.152 64.352C352.032 65.648 348.648 66.296 345 66.296Z" fill="black"/>
                </g>
                <g id="LName">
                    <path id="LR" d="M47.376 135H8.856V84.888H28.368C32.544 84.888 35.952 85.632 38.592 87.12C41.184 88.56 43.2 90.528 44.64 93.024C46.08 95.52 46.8 98.28 46.8 101.304C46.8 105.048 45.648 108.408 43.344 111.384C41.04 114.312 38.136 116.256 34.632 117.216L47.376 135ZM13.392 87.552L32.976 114.912C36.192 114.24 38.856 112.632 40.968 110.088C43.08 107.544 44.136 104.616 44.136 101.304C44.136 98.664 43.488 96.264 42.192 94.104C40.896 91.944 39.072 90.288 36.72 89.136C34.608 88.08 31.848 87.552 28.44 87.552H13.392ZM11.52 89.28V115.128H30.024L11.52 89.28ZM11.52 132.336H42.336L31.824 117.72L29.592 117.792H11.52V132.336Z" fill="black"/>
                    <path id="LO" d="M58.76 104.4C58.76 100.656 59.696 97.176 61.568 93.96C63.44 90.744 65.96 88.2 69.128 86.328C72.344 84.456 75.848 83.52 79.64 83.52C83.432 83.52 86.912 84.456 90.08 86.328C93.296 88.2 95.84 90.744 97.712 93.96C99.584 97.176 100.52 100.656 100.52 104.4V115.488C100.52 119.232 99.584 122.712 97.712 125.928C95.84 129.144 93.296 131.688 90.08 133.56C86.912 135.432 83.432 136.368 79.64 136.368C75.848 136.368 72.344 135.432 69.128 133.56C65.96 131.688 63.44 129.144 61.568 125.928C59.696 122.712 58.76 119.232 58.76 115.488V104.4ZM71.864 115.488C71.864 117.792 72.584 119.76 74.024 121.392C75.464 123.024 77.336 123.84 79.64 123.84C81.896 123.84 83.744 123.024 85.184 121.392C86.624 119.76 87.344 117.792 87.344 115.488V104.328C87.344 101.976 86.624 100.008 85.184 98.424C83.744 96.84 81.896 96.048 79.64 96.048C77.336 96.048 75.464 96.84 74.024 98.424C72.584 100.008 71.864 101.976 71.864 104.328V115.488Z" fill="black"/>
                    <path id="LC" d="M153.304 116.064C153.352 122.352 151.552 127.272 147.904 130.824C144.256 134.328 139.24 136.08 132.856 136.08C129.064 136.08 125.56 135.216 122.344 133.488C119.176 131.76 116.656 129.312 114.784 126.144C112.912 122.976 111.976 119.328 111.976 115.2V104.688C111.976 100.608 112.864 96.984 114.64 93.816C116.464 90.648 118.936 88.2 122.056 86.472C125.224 84.696 128.752 83.808 132.64 83.808C139.024 83.808 144.016 85.56 147.616 89.064C151.264 92.52 153.112 97.416 153.16 103.752H140.2C140.152 101.304 139.48 99.408 138.184 98.064C136.936 96.72 135.088 96.048 132.64 96.048C130.432 96.048 128.608 96.744 127.168 98.136C125.728 99.528 125.008 101.496 125.008 104.04V115.776C125.008 118.32 125.752 120.312 127.24 121.752C128.776 123.144 130.648 123.84 132.856 123.84C137.896 123.84 140.416 121.248 140.416 116.064H153.304Z" fill="black"/>
                    <path id="LK" d="M186.36 105.768L207.24 135H166.92V84.888H207.24L186.36 105.768ZM184.488 107.64L169.584 122.544V132.336H202.128L184.488 107.64Z" fill="black"/>
                    <path id="LE" d="M220.64 84.888H257.144V97.56H233.744V103.896H253.184V115.92H233.744V122.328H257.144V135H220.64V84.888Z" fill="black"/>
                    <path id="LL1" d="M313.528 122.256V135H275.368V84.888H278.032V122.256H313.528Z" fill="black"/>
                    <path id="LL2" d="M366.528 122.256V135H328.368V84.888H331.032V122.256H366.528Z" fill="black"/>
                </g>
            </g>
        </svg>  
    )
}

export default Name